var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { getChildrenRegion, getPlatformInfo, Mode, newGuid } from "@app/util";
import { GlCommonAppVersion } from "@app/util/conversation-api/utils/constants";
import { GLGlobal } from "gl-commonui";
import moment from 'moment';
import { PlaylistlogsAppService } from "../app/playlistlogs";
import { ReportService } from "../app/report";
import { SettingService } from "../app/setting";
export function getUploadPlaylistLogsService(pid, playlists, log) {
    return __awaiter(this, void 0, void 0, function () {
        var playlistlogAppService, studentId, unit, serverlogs, logs, curPlaylist, round;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    playlistlogAppService = new PlaylistlogsAppService();
                    studentId = log.studentId, unit = log.unit;
                    return [4 /*yield*/, playlistlogAppService.getPlaylistlog({ unit: unit, studentId: studentId })];
                case 1:
                    serverlogs = _a.sent();
                    logs = serverlogs && serverlogs.logs ? JSON.parse(serverlogs.logs) : [];
                    curPlaylist = playlists.find(function (pl) { return pl.id == pid; });
                    round = serverlogs ? serverlogs.round : 1;
                    if (logs.length == 10 && unit == GLGlobal.store.getState().theme.leftData.unit) {
                        logs = [];
                        round++;
                    }
                    return [2 /*return*/, { playlistlogAppService: playlistlogAppService, round: round, playlist: curPlaylist, logs: logs, log: log }];
            }
        });
    });
}
export function verification(pid, playlists, log) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, playlist, logs;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getUploadPlaylistLogsService(pid, playlists, log)];
                case 1:
                    _a = _b.sent(), playlist = _a.playlist, logs = _a.logs;
                    if (logs.find(function (log) { return log.seq == playlist.sequence - 1; }) || playlist.sequence == 1) {
                        return [2 /*return*/, { enable: true, playlist: playlist, logs: logs }];
                    }
                    else {
                        return [2 /*return*/, { enable: false, playlist: playlist, logs: logs }];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export default function uploadPlaylistLogs(serviceContext, contents, vpcCount, isNeedUploadPlaylogs) {
    return __awaiter(this, void 0, void 0, function () {
        var playlistlogAppService, round, playlist, logs, log, studentId_1, unit, maxUnit, pid_1, date, reportService, pos, sequence, _a, parentId, rest, currentUnit, settingService, mySetting, child, _b, bVersion, oVersion, os, browser, rest, contentPlayLog;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!serviceContext) return [3 /*break*/, 5];
                    playlistlogAppService = serviceContext.playlistlogAppService, round = serviceContext.round, playlist = serviceContext.playlist, logs = serviceContext.logs, log = serviceContext.log;
                    studentId_1 = log.studentId, unit = log.unit, maxUnit = log.maxUnit;
                    pid_1 = playlist.id;
                    date = moment(new Date()).utc().format();
                    reportService = new ReportService();
                    if (!(round < 5 && !logs.some(function (log) { return log.pid == pid_1; }))) return [3 /*break*/, 4];
                    log.round = round;
                    pos = logs.length;
                    sequence = playlist.sequence;
                    logs.push({ pos: pos, seq: sequence, img: pos, pid: pid_1, date: date });
                    return [4 /*yield*/, playlistlogAppService.sendPlaylistlog([__assign({}, log, { logs: JSON.stringify(logs) })])];
                case 1:
                    _c.sent();
                    _a = GLGlobal.store.getState().theme.leftData, parentId = _a.parentId, rest = __rest(_a, ["parentId"]);
                    currentUnit = rest.unit || rest.lsUnit;
                    if (logs.length == 10) {
                        updateAppSettings(studentId_1, parentId, currentUnit, { slowSpeed: false });
                    }
                    if (!unit) return [3 /*break*/, 4];
                    settingService = new SettingService();
                    return [4 /*yield*/, getMySetting(settingService, studentId_1, unit, pid_1)];
                case 2:
                    mySetting = (_c.sent()).mySetting;
                    return [4 /*yield*/, settingService.sendStudentSettings({ studentId: mySetting.userId, appSettings: JSON.stringify(mySetting.appSettings) })];
                case 3:
                    _c.sent();
                    _c.label = 4;
                case 4:
                    if (isNeedUploadPlaylogs) {
                        child = getChildrenRegion().find(function (u) { return u.id === studentId_1; });
                        _b = getPlatformInfo(), bVersion = _b.bVersion, oVersion = _b.oVersion, os = _b.os, browser = _b.browser, rest = __rest(_b, ["bVersion", "oVersion", "os", "browser"]);
                        contentPlayLog = __assign({ id: newGuid(), studentId: studentId_1, regionId: child ? child.regionId : null, browserVersion: bVersion, oSVersion: oVersion, operatingSystemId: os, browserId: browser }, rest, { playlistId: pid_1, contentId: null, contentTitle: "", contentGroupId: null, mode: Mode.Complete, startClientTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), start: moment(new Date()).utc(), unit: unit,
                            round: round, schoolId: child.schoolId ? child.schoolId : null, schoolClassId: child.schoolClassId ? child.schoolClassId : null, campusId: child.campusId ? child.campusId : null, AppVersion: GlCommonAppVersion });
                        return [2 /*return*/, Promise.all([
                                playlistlogAppService.sendPlaylistlogPlaylog([{ createTime: moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss'), studentId: studentId_1, unit: unit, playlistId: pid_1 }]),
                                reportService.sendElklogsV2([__assign({}, contentPlayLog)])
                            ])];
                    }
                    return [2 /*return*/, Promise.resolve()];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function updateAppSettings(studentId, parentId, maxUnit, kvPair) {
    return __awaiter(this, void 0, void 0, function () {
        var settingService, mySetting;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    settingService = new SettingService();
                    return [4 /*yield*/, getMySetting(settingService, studentId, maxUnit)];
                case 1:
                    mySetting = (_a.sent()).mySetting;
                    Object.keys(kvPair).forEach(function (key) {
                        mySetting.appSettings[key] = kvPair[key];
                    });
                    return [4 /*yield*/, settingService.sendStudentSettings({ studentId: mySetting.userId, parentId: mySetting.userId, appSettings: JSON.stringify(mySetting.appSettings) })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function getMySetting(settingService, userId, maxUnit, pid) {
    return __awaiter(this, void 0, void 0, function () {
        var setting, mySetting, todayStandardKey, todayPlayPlaylists, serverTodayPlayPlaylists, todayValues, newTodayValues, todayDate, todayMaxLogValues;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, settingService.getSetting({ maxUnit: maxUnit }, { childId: userId })];
                case 1:
                    setting = _a.sent();
                    mySetting = {};
                    mySetting.userId = userId;
                    mySetting.maxRound = setting.maxRound;
                    mySetting.appSettings = setting && setting.appSettings ? JSON.parse(setting.appSettings) : {};
                    todayStandardKey = moment(new Date()).format("YYYY-MM-DD") + "-" + maxUnit;
                    todayPlayPlaylists = {};
                    todayPlayPlaylists[todayStandardKey] = [];
                    serverTodayPlayPlaylists = mySetting.appSettings.todayPlayPlaylists ? JSON.parse(mySetting.appSettings.todayPlayPlaylists) : {};
                    if (serverTodayPlayPlaylists && Object.keys(serverTodayPlayPlaylists).indexOf(todayStandardKey) >= 0) { // agar server me aaj ke standard key ki playlist ni h
                        todayValues = serverTodayPlayPlaylists[todayStandardKey];
                        newTodayValues = todayValues && Array.isArray(todayValues) ? todayValues : [];
                        todayPlayPlaylists = serverTodayPlayPlaylists;
                        todayPlayPlaylists[todayStandardKey] = newTodayValues;
                    }
                    else {
                        todayPlayPlaylists = serverTodayPlayPlaylists ? serverTodayPlayPlaylists : {};
                        todayPlayPlaylists[todayStandardKey] = [];
                    }
                    if (pid) {
                        if (todayPlayPlaylists[todayStandardKey].indexOf(pid) < 0) {
                            todayPlayPlaylists[todayStandardKey].push(pid);
                        }
                    }
                    todayDate = moment(new Date()).format("YYYY-MM-DD");
                    // Delete older keys
                    Object.keys(todayPlayPlaylists).forEach(function (key) {
                        var keyDate = key.split('-').slice(0, 3).join('-');
                        if (moment(keyDate).isBefore(todayDate)) {
                            delete todayPlayPlaylists[key];
                        }
                    });
                    mySetting.appSettings.todayPlayPlaylists = JSON.stringify(todayPlayPlaylists);
                    todayMaxLogValues = todayPlayPlaylists[todayStandardKey];
                    return [2 /*return*/, { mySetting: mySetting, todayMaxLogValues: todayMaxLogValues }];
            }
        });
    });
}
export function getProgress(studentId, unit, pid) {
    return __awaiter(this, void 0, void 0, function () {
        var service, progress;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    service = new PlaylistlogsAppService();
                    return [4 /*yield*/, service.getProgress({}, { studentId: studentId, unit: unit })];
                case 1:
                    progress = _a.sent();
                    return [2 /*return*/, progress && progress.playlistId == pid ? progress : null];
            }
        });
    });
}
export function uploadProgress(studentId, unit, pid, contentIndex) {
    return __awaiter(this, void 0, void 0, function () {
        var service, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    service = new PlaylistlogsAppService();
                    _a = contentIndex !== undefined && contentIndex !== null;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, service.sendProgress({ studentId: studentId, unit: unit, contentIndex: contentIndex, playlistId: pid, updateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") }, { studentId: studentId, unit: unit })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    return [2 /*return*/];
            }
        });
    });
}
