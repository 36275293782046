var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SettingService } from '@app/service/app/setting';
import { getMySetting } from '@app/service/logs/uploadplaylistlogs';
import { GLGlobal, GLUtil } from 'gl-commonui';
import { PlayerRole, RoleHelper } from '@app/util';
import { PathConfig } from '@app/config/pathconfig';
export function PagePermission(userId, unit) {
    var role = GLGlobal.store.getState().theme.leftData.role;
    if (!GLGlobal.store.getState().theme.leftData.isLoaded) {
        return false;
    }
    else if (!unit) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (userId != GLGlobal.store.getState().theme.leftData.id) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (unit > GLGlobal.store.getState().theme.leftData.unit) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (!(role == PlayerRole.Digital || role == PlayerRole.Dual || role == PlayerRole.Other)) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    return true;
}
export default function PermissionParse(userId, userRole, pls, mySetting) {
    return __awaiter(this, void 0, void 0, function () {
        var rest, maxUnit, settingService, _a, todayMaxLogValues, setting, _b, todayMaxLogValuesForCurrentUnit, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!RoleHelper.isStudent(userRole)) return [3 /*break*/, 10];
                    rest = GLGlobal.store.getState().theme.leftData;
                    maxUnit = rest.unit || rest.lsUnit;
                    pls.maxUnit = maxUnit;
                    if (!(maxUnit > 0)) return [3 /*break*/, 9];
                    settingService = new SettingService();
                    if (!mySetting) return [3 /*break*/, 2];
                    return [4 /*yield*/, Promise.resolve(mySetting)];
                case 1:
                    _b = _d.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, getMySetting(settingService, userId, maxUnit)];
                case 3:
                    _b = _d.sent();
                    _d.label = 4;
                case 4:
                    _a = _b, todayMaxLogValues = _a.todayMaxLogValues, setting = _a.mySetting;
                    if (!mySetting) return [3 /*break*/, 6];
                    return [4 /*yield*/, Promise.resolve(mySetting)];
                case 5:
                    _c = _d.sent();
                    return [3 /*break*/, 8];
                case 6: return [4 /*yield*/, getMySetting(settingService, userId, pls.unit)];
                case 7:
                    _c = _d.sent();
                    _d.label = 8;
                case 8:
                    todayMaxLogValuesForCurrentUnit = (_c).todayMaxLogValues;
                    pls.completedRoundOne = false;
                    if (setting.maxRound > 4) {
                        pls.completedRoundOne = true;
                        pls.isFinishedDailyTask = true;
                        enablePlaylist(pls, function (index, playingIndex) { return index <= playingIndex; });
                        return [2 /*return*/];
                    }
                    pls.isFinishedDailyTask = todayMaxLogValues != null && todayMaxLogValues.length >= 1;
                    if (pls.unit == maxUnit) {
                        pls.isFinishedMaxNewPlaylistsTask = todayMaxLogValues != null && todayMaxLogValues.length >= 2;
                    }
                    else {
                        if (!pls.isFinishedDailyTask) {
                            pls.isFinishedMaxNewPlaylistsTask = false;
                            return [2 /*return*/];
                        }
                        pls.isFinishedMaxNewPlaylistsTask = todayMaxLogValuesForCurrentUnit != null && todayMaxLogValuesForCurrentUnit.length >= 2;
                    }
                    _d.label = 9;
                case 9:
                    if (pls.isFinishedDailyTask) {
                        if (pls.isFinishedMaxNewPlaylistsTask) {
                            enablePlaylist(pls, function (index, playingIndex) { return index < playingIndex; });
                        }
                        else {
                            enablePlaylist(pls, function (index, playingIndex) { return index <= playingIndex; });
                        }
                    }
                    else {
                        enablePlaylist(pls, function (index, playingIndex) { return index == playingIndex; });
                    }
                    return [3 /*break*/, 11];
                case 10:
                    enablePlaylist(pls, function (index, playingIndex) { return index <= playingIndex; });
                    _d.label = 11;
                case 11: return [2 /*return*/];
            }
        });
    });
}
function enablePlaylist(pls, compare) {
    pls && pls.playlists && pls.playlists.forEach(function (playlist) {
        playlist.isEnable = pls.round > 4 || compare(playlist.index, pls.playingIndex);
    });
}
export function buildUnitPlaylist(unitsPlaylists, log, unit) {
    var unitPlaylist = {
        unit: unit,
        round: 1,
        playingIndex: 0,
        playlists: unitsPlaylists
            .sort(function (pre, next) { return pre.sequence - next.sequence; })
            .map(function (playlist, index) { return ({
            index: index,
            pos: index,
            seq: index + 1,
            img: index,
            pid: playlist.id,
            conversationSpeed: playlist.conversationSpeed,
        }); })
    };
    var badgePercentage = 0;
    var hasPlayed = !!log;
    if (hasPlayed) {
        log.logs = log.logs ? JSON.parse(log.logs) : [];
        badgePercentage = getPlaylistBadgePercentage(log);
        unitPlaylist.round = log.round;
        unitPlaylist.playingIndex = log.logs.length;
        unitPlaylist.playlists = orderRoundPlaylist(log.round, unitPlaylist.playlists);
        log.logs = adjustLogs(unitPlaylist.playlists, log.logs);
    }
    return { unitPlaylist: unitPlaylist, badgePercentage: badgePercentage, log: log, hasPlayed: hasPlayed };
}
function getPlaylistBadgePercentage(log) {
    var count = (log.round > 4 || log.logs.length > 10) ? 10 : log.logs.length;
    var percentage = {
        1: 24,
        2: 44,
        3: 59,
        4: 70,
        5: 79,
        6: 86,
        7: 91,
        8: 95,
        9: 98,
        10: 100,
    };
    return percentage[count];
}
function orderRoundPlaylist(round, playlist) {
    var orderMap = {
        1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        2: [1, 0, 7, 8, 9, 6, 5, 2, 4, 3],
        3: [7, 2, 1, 5, 4, 0, 8, 3, 9, 6],
        4: [0, 4, 7, 5, 8, 6, 9, 1, 2, 3]
    };
    return orderMap[round > 4 ? 4 : round].map(function (pos, index) { return (__assign({}, playlist[pos], { index: index, pos: index, img: index })); });
}
export function adjustLogs(playlist, logs) {
    return logs.map(function (log, index) {
        log.pos = playlist[index].pos;
        log.seq = playlist[index].seq;
        log.img = playlist[index].img;
        log.pid = playlist[index].pid;
        return log;
    });
}
