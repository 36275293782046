var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import { Spin } from "antd-min";
import { addMediaAttributesForIOS, IEVersion, isMobileOrTablet, polyfillNodeList, EventBus } from "@app/util";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
export var technologyType;
(function (technologyType) {
    technologyType["azureHtml5JS"] = "azureHtml5JS";
    technologyType["flashSS"] = "flashSS";
    technologyType["html5FairPlayHLS"] = "html5FairPlayHLS";
    technologyType["silverlightSS"] = "silverlightSS";
    technologyType["html5"] = "html5";
})(technologyType || (technologyType = {}));
export var PlayAction;
(function (PlayAction) {
    PlayAction["play"] = "play";
    PlayAction["pause"] = "pause";
    PlayAction["stop"] = "stop";
    PlayAction["fullscreen"] = "full-screen";
    PlayAction["exitFullscreen"] = "exit-full-screen";
    PlayAction["stepbackward"] = "step-backward";
    PlayAction["stepforward"] = "step-forward";
})(PlayAction || (PlayAction = {}));
export var MediaStreamType;
(function (MediaStreamType) {
    MediaStreamType["audio"] = "audio/mp3";
    MediaStreamType["video"] = "video/mp4";
    MediaStreamType["smooth"] = "application/vnd.ms-sstr+xml";
    MediaStreamType["m3u8"] = "application/x-mpegURL";
})(MediaStreamType || (MediaStreamType = {}));
export var MediaType;
(function (MediaType) {
    MediaType["audio"] = "audio";
    MediaType["video"] = "video";
})(MediaType || (MediaType = {}));
export var MediaExtType;
(function (MediaExtType) {
    MediaExtType["PDF"] = "PDF";
    MediaExtType["MP3"] = "MP3";
    MediaExtType["MP4"] = "MP4";
    MediaExtType["PNG"] = "PNG";
})(MediaExtType || (MediaExtType = {}));
export var MediaDomDisplay;
(function (MediaDomDisplay) {
    MediaDomDisplay["hidden"] = "hidden";
    MediaDomDisplay["visible"] = "visible";
    MediaDomDisplay["audioShow"] = "audioShow";
    MediaDomDisplay["videoShow"] = "videoShow";
})(MediaDomDisplay || (MediaDomDisplay = {}));
export var PlayMode;
(function (PlayMode) {
    PlayMode["random"] = "random";
    PlayMode["order"] = "order";
    PlayMode["single"] = "single";
    PlayMode["singleCycle"] = "singlecycle";
})(PlayMode || (PlayMode = {}));
export var SequenceMode;
(function (SequenceMode) {
    SequenceMode["single"] = "single";
    SequenceMode["singleCycle"] = "singlecycle";
})(SequenceMode || (SequenceMode = {}));
var IntervalEnum;
(function (IntervalEnum) {
    IntervalEnum["pre"] = "pre";
    IntervalEnum["mid"] = "mid";
    IntervalEnum["post"] = "post";
})(IntervalEnum || (IntervalEnum = {}));
export var ContentGroup;
(function (ContentGroup) {
    ContentGroup["Conversation"] = "Conversation";
    ContentGroup["Movie"] = "Movie";
    ContentGroup["VPC"] = "VPC";
    ContentGroup["Song"] = "Song";
})(ContentGroup || (ContentGroup = {}));
var MediaPlayer = /** @class */ (function (_super) {
    __extends(MediaPlayer, _super);
    function MediaPlayer(props) {
        var _this = _super.call(this, props) || this;
        _this.playedSequenceContents = [];
        _this.sequenceContents = [];
        _this.contents = [];
        _this.mediaContext = {};
        _this.controller = {
            eventBus: new EventBus(),
            addEventListener: function (type, callback) { return _this.controller.eventBus.addEventListener(type, callback); },
            removeEventListener: function (type, callback) { return _this.controller.eventBus.removeEventListener(type, callback); },
            dispatchEvent: function (event) { return _this.controller.eventBus.dispatchEvent(event); },
            sendPlay: function () { return _this.controller.dispatchEvent({ type: 'play' }); },
            sendPause: function () { return _this.controller.dispatchEvent({ type: 'pause' }); },
            sendEnded: function () { return _this.controller.dispatchEvent({ type: 'ended' }); },
            sendCurrentEnded: function () { return _this.controller.dispatchEvent({ type: 'currentEnded' }); },
            repeat: function (again) {
                var description = _this.mediaContext.content.description;
                if (description.single) {
                    again ? _this.playCurrent() : _this.playNext(IntervalEnum.pre);
                }
            }
        };
        _this.state = {
            controlBarShow: props.visibleBar || false,
            loadeddata: true,
            startIndex: null,
        };
        return _this;
    }
    MediaPlayer.prototype.componentDidMount = function () {
        var _this = this;
        var myOptions = {
            nativeControlsForTouch: false,
            controls: false,
            autoplay: this.props.autoplay === false ? false : true,
            logo: { enabled: false },
            'webkit-playsinline': true,
            playsinline: true,
        };
        this.videoPlayer = videojs("videojs-video-player", myOptions);
        this.addEventListeners(this.videoPlayer);
        this.audioPlayer = videojs("videojs-audio-player", myOptions);
        this.addEventListeners(this.audioPlayer);
        this.audioBackgroundPlayer = videojs("background-audio", myOptions);
        this.repeatBackgroundAudio();
        this.props.getController && this.props.getController(this.controller);
        var timer;
        this.resizeListener = function () {
            if (!timer) {
                timer = setTimeout(function () {
                    clearTimeout(timer);
                    timer = null;
                    _this.resetDimensions();
                }, 50);
            }
        };
        window.addEventListener("resize", this.resizeListener);
        var mask = document.querySelector("#videojs-audio-player .vjs-poster-span");
        var prevent = function (e) {
            e.stopPropagation();
            _this.controlContainerShow(e);
            return false;
        };
    };
    MediaPlayer.prototype.componentWillUnmount = function () {
        clearInterval(this.controlBarShowTimer);
        if (this.videoPlayer) {
            this.videoPlayer.dispose();
        }
        if (this.audioPlayer) {
            this.audioPlayer.dispose();
        }
        if (this.audioBackgroundPlayer) {
            this.audioBackgroundPlayer.dispose();
        }
        window.removeEventListener('resize', this.resizeListener);
    };
    MediaPlayer.prototype.componentDidUpdate = function () {
        var _this = this;
        this.props.playbackRate && Array.from(document.getElementsByTagName("video")).forEach(function (video) { return video.playbackRate = _this.props.playbackRate; });
        this.props.playbackRate && Array.from(document.getElementsByTagName("audio")).forEach(function (audio) { return audio.playbackRate = _this.props.playbackRate; });
    };
    MediaPlayer.prototype.resetDimensions = function () {
        var posterImg = document.querySelector('#videojs-audio-player .vjs-poster img');
        posterImg && setDimensions(posterImg.width, posterImg.height);
        var video = document.querySelector('#videojs-video-player video');
        if (video) {
            var _a = flexVideo(video), width = _a.width, height = _a.height;
            setDimensions(width, height);
        }
    };
    MediaPlayer.prototype.repeatBackgroundAudio = function () {
        var _this = this;
        this.audioBackgroundPlayer.on(amp.eventName.ended, function () {
            _this.audioBackgroundPlayer.currentTime(0);
            _this.audioBackgroundPlayer.play();
        });
    };
    MediaPlayer.prototype.addEventListeners = function (player) {
        var _this = this;
        player.on(amp.eventName.loadedmetadata, function () {
            _this.setState({ loadeddata: false });
        });
        player.on(amp.eventName.loadeddata, function () {
            _this.setState({ loadeddata: true });
        });
        player.on(amp.eventName.play, function () {
            _this.controller.sendPlay();
        });
        player.on(amp.eventName.pause, function () {
            _this.mediaContext.action !== PlayAction.stop && _this.controller.sendPause();
        });
        player.on(amp.eventName.ended, function () {
            if (_this.mediaContext.action !== PlayAction.stop) {
                _this.controller.sendCurrentEnded();
                _this.props.onTimeUpdate({ time: -1, duration: -1 });
                _this.playMedia();
            }
        });
        player.on(amp.eventName.timeupdate, function () {
            var pages = _this.mediaContext.content.pages;
            if ((_this.props.visibleMedia !== false) && pages && pages.length > 1) {
                var time_1 = player.currentTime() * 1000;
                var matchedPager = pages.find(function (page) { return page.pageTiming > time_1; });
                if (matchedPager && player.poster() !== matchedPager.url) {
                    player.poster(matchedPager.url);
                }
            }
            if (_this.props.onTimeUpdate) {
                var time = player.currentTime(), duration = player.duration();
                _this.props.onTimeUpdate({ time: time, duration: duration });
            }
        });
    };
    MediaPlayer.prototype.componentWillReceiveProps = function (_a) {
        var resources = _a.resources, visibleMedia = _a.visibleMedia, startIndex = _a.startIndex, playMode = _a.playMode, playbackRate = _a.playbackRate;
        if (resources.contents && resources.contents.length > 0) {
            if (this.props.resources !== resources) {
                controlMediaDom(this.visibleMedia(MediaDomDisplay.visible, visibleMedia));
                setMediaInlineForIOS();
                this.sequenceContents = this.contents = resources.contents;
                this.setStartIndexMode(startIndex, playMode);
                return;
            }
            if (this.props.playMode !== playMode) {
                this.setStartIndexMode(this.props.startIndex, playMode);
            }
            if (startIndex && this.props.startIndex !== startIndex && this.state.startIndex !== startIndex) {
                this.setStartIndexMode(startIndex, this.props.playMode);
                this.setState({ startIndex: startIndex });
            }
            else if (startIndex == null) {
                this.setState({ startIndex: startIndex });
            }
            // if(this.props.playbackRate !== playbackRate) {
            //     playbackRate && Array.from(document.getElementsByTagName("video")).forEach(video => video.playbackRate = playbackRate)
            //     playbackRate && Array.from(document.getElementsByTagName("audio")).forEach(audio => audio.playbackRate = playbackRate)
            // }
        }
    };
    MediaPlayer.prototype.visibleMedia = function (type, visibleMedia) {
        return (visibleMedia || this.props.visibleMedia) === false ? MediaDomDisplay.hidden : type;
    };
    MediaPlayer.prototype.setController = function (actions) {
        var _this = this;
        Object.getOwnPropertyNames(actions).forEach(function (action) {
            _this.controller[action] = actions[action];
        });
    };
    MediaPlayer.prototype.setStartIndexMode = function (id, mode) {
        this.setSequenceContents(id, mode || this.mediaContext.mode || PlayMode.order);
        this.playNext(IntervalEnum.pre);
    };
    MediaPlayer.prototype.setMedia = function (content) {
        var _this = this;
        var setStepward = function (isForward) {
            if (_this.mediaContext.mode === PlayMode.single || _this.mediaContext.mode === PlayMode.singleCycle) {
                return;
            }
            var sequence = _this.contents.findIndex(function (c) { return c.id === _this.mediaContext.content.id; });
            sequence = isForward
                ? (sequence === (_this.contents.length - 1) ? 0 : sequence + 1)
                : (sequence === 0 ? _this.contents.length - 1 : sequence - 1);
            _this.setStartIndexMode(_this.contents[sequence].id, _this.mediaContext.mode);
            _this.setState({ startIndex: _this.contents[sequence].id });
            window.setTimeout(function () {
                _this.setState({ startIndex: null });
            }, 100);
        };
        var set = function (player, type, domAction, callbacks) {
            var src = content.medias.map(function (m) { return ({ src: m.url, type: content.description.type || type }); });
            content.src = src;
            callbacks && callbacks.setPoster(player);
            var tempDescription = __assign({}, content.description);
            _this.setController({
                play: function () {
                    player.autoplay(true);
                    player.play();
                    callbacks && callbacks.play();
                },
                pause: function () {
                    player.pause();
                    callbacks && callbacks.pause();
                },
                paused: function () { return player.paused(); },
                stop: function () {
                    _this.mediaContext.action = PlayAction.stop;
                    _this.sequenceContents = [];
                    player.currentTime(player.duration());
                    callbacks && callbacks.pause();
                },
                seek: function (seconds) { return player.currentTime(seconds); },
                setPlayMode: function (mode) {
                    switch (mode) {
                        case PlayMode.single:
                            _this.mediaContext.mode = PlayMode.single;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: 0, single: true });
                            break;
                        case PlayMode.singleCycle:
                            _this.mediaContext.mode = PlayMode.singleCycle;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: Number.MAX_VALUE, single: false });
                            break;
                        case PlayMode.random:
                            _this.mediaContext.mode = PlayMode.random;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: 0, single: false });
                            _this.sequenceContents = _this.randomContents();
                            break;
                        case PlayMode.order:
                            _this.mediaContext.mode = PlayMode.order;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: 0, single: false });
                            _this.sequenceContents = _this.contents.slice(_this.contents.findIndex(function (c) { return c.id === content.id; }) + 1);
                            break;
                        default:
                            break;
                    }
                },
                setStartIndex: function (_a) {
                    var id = _a.id, mode = _a.mode;
                    _this.setStartIndexMode(id, mode);
                },
                enterFullscreen: function () { return player.enterFullscreen(); },
                exitFullscreen: function () { return player.exitFullscreen(); },
                isFullscreen: function () { return player.isFullscreen(); },
                stepBackward: function () { return setStepward(false); },
                stepForward: function () { return setStepward(true); },
            });
            controlMediaDom(_this.visibleMedia(domAction), player);
            return player;
        };
        this.playBackgroundAudio(content);
        if (content.description.mediaType === MediaType.audio) {
            return set(this.audioPlayer, MediaStreamType.audio, MediaDomDisplay.audioShow, {
                setPoster: function (player) { return (_this.props.visibleMedia !== false) && content.pages.length > 0 && player.poster(content.pages[0].url); },
                play: function () { return _this.resumeBackgroundAudio(); },
                pause: function () { return _this.pauseBackgroundAudio(); },
            });
        }
        else if (content.description.mediaType === MediaType.video) {
            return set(this.videoPlayer, MediaStreamType.video, MediaDomDisplay.videoShow);
        }
    };
    MediaPlayer.prototype.playBackgroundAudio = function (content) {
        if (content.description.backgroundAudio) {
            if (content.description.backgroundAudio.start) {
                this.audioBackgroundPlayer.src([{ src: content.description.backgroundAudio.uri, type: MediaStreamType.audio }]);
                this.audioBackgroundPlayer.play();
            }
        }
        else {
            this.pauseBackgroundAudio();
        }
    };
    MediaPlayer.prototype.resumeBackgroundAudio = function () {
        this.audioBackgroundPlayer.play();
    };
    MediaPlayer.prototype.pauseBackgroundAudio = function () {
        this.audioBackgroundPlayer.pause();
    };
    MediaPlayer.prototype.setSequenceContents = function (id, mode) {
        var _this = this;
        var setSingleMode = function () {
            if (mode === PlayMode.single) {
                _this.sequenceContents[0].description.singleCycle = 0;
                _this.sequenceContents[0].description.single = true;
            }
            else if (mode === PlayMode.singleCycle) {
                _this.sequenceContents[0].description.singleCycle = Number.MAX_VALUE;
                _this.sequenceContents[0].description.single = false;
            }
        };
        if (id && mode) {
            this.mediaContext.mode = mode;
            if (mode === PlayMode.random) {
                this.sequenceContents = this.randomContents(id);
            }
            else {
                this.sequenceContents = this.contents.slice(this.contents.findIndex(function (c) { return c.id === id; }));
                setSingleMode();
            }
        }
        else if (id) {
            this.sequenceContents = this.contents.slice(this.contents.findIndex(function (c) { return c.id === id; }));
        }
        else if (mode) {
            this.mediaContext.mode = mode;
            if (mode === PlayMode.random) {
                this.sequenceContents = this.randomContents();
            }
            else {
                this.sequenceContents = __spread(this.contents);
                setSingleMode();
            }
        }
    };
    MediaPlayer.prototype.randomContents = function (id) {
        var _this = this;
        if (this.contents.length <= this.playedSequenceContents.length) {
            this.playedSequenceContents = [];
        }
        return this.contents
            .filter(function (c) { return c.id === id; })
            .concat(this.contents
            .filter(function (c) { return c.id !== id; })
            .filter(function (c) { return !_this.playedSequenceContents.some(function (pc) { return c.id === pc.id; }); })
            .sort(random));
    };
    MediaPlayer.prototype.playMedia = function () {
        var _this = this;
        if (this.props.sequenceMode === SequenceMode.singleCycle
            && this.sequenceContents.length === 0
            && this.mediaContext.mode !== PlayMode.single
            && this.mediaContext.mode !== PlayMode.singleCycle) {
            this.setSequenceContents(null, this.mediaContext.mode ? this.mediaContext.mode : PlayMode.order);
        }
        if (this.sequenceContents.length === 0) {
            this.pauseBackgroundAudio();
            this.controller.sendEnded();
        }
        else {
            var _a = __read(this.playRepeat(), 2), isRepeat = _a[0], interval = _a[1];
            if (!isRepeat) {
                if (interval && interval.post) {
                    setTimeout(function () { return _this.playNext(IntervalEnum.pre); }, interval.post);
                }
                else {
                    this.playNext(IntervalEnum.pre);
                }
            }
        }
    };
    MediaPlayer.prototype.playCurrent = function () {
        var _a = __read(this.currentContext(), 2), play = _a[0], description = _a[1];
        this.playDelay(play, description.interval, IntervalEnum.mid);
    };
    MediaPlayer.prototype.playRepeat = function () {
        var _a = __read(this.currentContext(), 2), play = _a[0], description = _a[1];
        if (description.single)
            return [true];
        if (description.singleCycle && description.singleCycle > 1) {
            description.singleCycle--;
            this.playDelay(play, description.interval, IntervalEnum.mid);
            return [true];
        }
        return [false, description.interval];
    };
    MediaPlayer.prototype.playDelay = function (play, interval, type) {
        if (interval && interval[type]) {
            setTimeout(function () { return play(); }, interval[type]);
        }
        else {
            play();
        }
    };
    MediaPlayer.prototype.playNext = function (type) {
        var _a = __read(this.nextContext(), 2), play = _a[0], interval = _a[1].interval;
        this.playDelay(play, interval, type);
    };
    MediaPlayer.prototype.nextContext = function () {
        var _this = this;
        var content = this.sequenceContents.shift();
        this.playedSequenceContents.push(content);
        var media = this.setMedia(content);
        this.mediaContext = __assign({}, this.mediaContext, { content: content,
            media: media });
        return [
            function () {
                if (content.src && content.src.length) {
                    media.src(content.src);
                    media.autoplay() && media.play();
                    _this.props.onCurrentStarted && _this.props.onCurrentStarted(_this.sequenceContents.length, content);
                    _this.setState({ startIndex: content.id });
                }
            },
            content.description
        ];
    };
    MediaPlayer.prototype.currentContext = function () {
        var _a = this.mediaContext, content = _a.content, media = _a.media;
        var smoothSrc = content.description.type === MediaStreamType.m3u8 ? content.src : null;
        return [
            function () {
                if (smoothSrc) {
                    media.src(smoothSrc);
                }
                else {
                    media.currentTime(0);
                }
                media.play();
            },
            content.description
        ];
    };
    MediaPlayer.prototype.controlContainerShow = function (e) {
        var _this = this;
        var visibleBar = this.props.visibleBar;
        if (!visibleBar && !this.state.controlBarShow) {
            this.setState({ controlBarShow: true }, function () { return _this.resetDimensions(); });
            clearInterval(this.controlBarShowTimer);
            this.controlBarShowTimer = setInterval(function () {
                if (!_this.mediaContext.media.paused()) {
                    _this.controlContainerHide();
                }
            }, 3000);
        }
        else {
            this.controlContainerHide();
        }
    };
    MediaPlayer.prototype.controlContainerHide = function () {
        var visibleBar = this.props.visibleBar;
        if (!visibleBar && !this.mediaContext.media.paused()) {
            clearInterval(this.controlBarShowTimer);
            this.setState({ controlBarShow: false });
        }
    };
    MediaPlayer.prototype.getComponentName = function (c) {
        return (c.type.displayName || c.type.name);
    };
    MediaPlayer.prototype.renderChildren = function () {
        var _this = this;
        var components = React.Children.toArray(this.props.children);
        var controlBar = components.find(function (c) { return _this.getComponentName(c).includes("ControlContainer"); });
        var subtitles = components.find(function (c) { return _this.getComponentName(c).endsWith("Subtitles"); });
        var subtitle = components.find(function (c) { return _this.getComponentName(c).endsWith("Subtitle"); });
        var others = components.filter(function (c) {
            var name = _this.getComponentName(c);
            return !name.includes("Subtitles") && !name.includes("Subtitle") && !name.includes("ControlContainer");
        });
        return (React.createElement(React.Fragment, null,
            others.length > 0 && others,
            this.state.controlBarShow && controlBar,
            subtitles,
            subtitle));
    };
    MediaPlayer.prototype.render = function () {
        var _this = this;
        var _a = this.state, controlBarShow = _a.controlBarShow, loadeddata = _a.loadeddata;
        var visibleMedia = this.props.visibleMedia;
        return React.createElement("div", { className: 'mediaplayer-wrapper', 
            // onMouseEnter={(e) => this.controlContainerShow(e)} 
            // onMouseLeave={(e) => this.controlContainerHide()} 
            onClick: function (e) { return _this.controlContainerShow(e); } },
            React.createElement("video", { id: "videojs-video-player", "webkit-playsinline": 'true', playsInline: true, className: "azuremediaplayer mediaplayer", tabIndex: 0 }),
            React.createElement("audio", { id: "videojs-audio-player", "webkit-playsinline": 'true', playsInline: true, className: "azuremediaplayer amp-default-skin amp-big-play-centered mediaplayer", tabIndex: 0 }),
            React.createElement("audio", { id: "background-audio", "webkit-playsinline": 'true', playsInline: true, className: "azuremediaplayer amp-default-skin amp-big-play-centered mediaplayer background", tabIndex: 0 }),
            React.createElement("div", { className: 'screen-controller media-screen-dimensions' },
                React.createElement(Spin, { delay: 500, size: 'large', spinning: visibleMedia !== false && !loadeddata })),
            this.renderChildren());
    };
    return MediaPlayer;
}(Component));
export default MediaPlayer;
function setMediaInlineForIOS() {
    if (IEVersion() == -1) {
        setAttribuiteForIOS('video');
        setAttribuiteForIOS('audio');
    }
}
function setAttribuiteForIOS(query) {
    var doms = polyfillNodeList(document.querySelectorAll(query));
    doms.forEach(function (dom) {
        addMediaAttributesForIOS(dom.id);
        dom.setAttribute("playsinline", "true");
    });
}
function controlMediaDom(type, player) {
    var videoMediaDom = document.getElementById('videojs-video-player');
    var audioMediaDom = document.getElementById('videojs-audio-player');
    var audioBackgroundMediaDom = document.getElementById('background-audio');
    videoMediaDom.style.display = 'none';
    audioMediaDom.style.display = 'none';
    audioBackgroundMediaDom.style.display = 'none';
    switch (type) {
        case MediaDomDisplay.visible:
            var videoDom = videoMediaDom.querySelector('video');
            var audioDom = audioMediaDom.querySelector('video');
            videoDom && (videoDom.style.display = 'block');
            audioDom && (audioDom.style.display = 'block');
            break;
        case MediaDomDisplay.audioShow:
            var posterImg_1 = document.querySelector('#videojs-audio-player .vjs-poster img');
            var imgLoad_1 = function () {
                audioMediaDom.style.maxHeight = '575px';
                audioMediaDom.style.maxWidth = '1024px';
                posterImg_1.naturalHeight && (audioMediaDom.style.maxHeight = posterImg_1.naturalHeight + "px");
                setDimensions(posterImg_1.width, posterImg_1.height);
                posterImg_1.removeEventListener('load', imgLoad_1);
            };
            posterImg_1.addEventListener('load', imgLoad_1);
            audioMediaDom.style.display = 'block';
            break;
        case MediaDomDisplay.videoShow:
            var resetDimensions_1 = function () {
                var video = document.querySelector('#videojs-video-player video');
                video.style.maxHeight = videoMediaDom.style.maxHeight = '575px';
                video.style.maxWidth = videoMediaDom.style.maxWidth = '1024px';
                var _a = flexVideo(video), width = _a.width, height = _a.height;
                setDimensions(width, height);
            };
            var loadstart_1 = function () {
                resetDimensions_1();
                player.off('loadstart', loadstart_1);
            };
            player.on('loadstart', loadstart_1);
            var loadeddata_1 = function () {
                resetDimensions_1();
                player.off('loadeddata', loadeddata_1);
            };
            player.on('loadeddata', loadeddata_1);
            videoMediaDom.style.display = 'block';
            break;
    }
}
function flexVideo(video) {
    if (window.innerWidth >= window.innerHeight) {
        var wrapper = document.querySelector('.mediaplayer-wrapper');
        video.style.height = '100%';
        video.style.width = '100%';
        var calcDimensions = function (height) {
            video.style.height = height + "px";
            return { width: calcDomWidth(height), height: height };
        };
        return calcDimensions(wrapper.clientHeight >= 575 ? 575 : wrapper.clientHeight);
    }
    else {
        video.style.width = '100%';
        video.style.height = calcVideoHeight(video);
        return { width: video.clientWidth, height: video.clientHeight };
    }
}
function calcVideoWidth(video) {
    return calcDomWidth(video.clientHeight) + "px";
}
function calcVideoHeight(video) {
    return calcDomHeight(video.clientWidth) + "px";
}
function calcDomWidth(height) {
    return height * 1.78;
}
function calcDomHeight(width) {
    return width / 1.78;
}
function setDimensions(width, height) {
    if (width === 0 || height === 0) {
        return;
    }
    width = width + "px";
    var mediaplayerController = document.querySelector('.mediaplayer-controlbar');
    if (mediaplayerController) {
        mediaplayerController.style.width = width;
        mediaplayerController.style.top = height - mediaplayerController.clientHeight + "px";
    }
    var mediaScreens = document.querySelectorAll('.media-screen-dimensions');
    for (var i = 0; mediaScreens && i < mediaScreens.length; i++) {
        var mediaScreen = mediaScreens[i];
        mediaScreen.style.width = width;
        mediaScreen.style.height = height + "px";
    }
    if (isMobileOrTablet()) {
        var mediaplayers = document.querySelectorAll('.mediaplayer');
        for (var i = 0; mediaplayers && i < mediaplayers.length; i++) {
            var mediaplayer = mediaplayers[i];
            mediaplayer.style.height = height + "px";
        }
    }
    else {
        var mediaplayers = document.querySelectorAll('.mediaplayer');
        for (var i = 0; mediaplayers && i < mediaplayers.length; i++) {
            var mediaplayer = mediaplayers[i];
            mediaplayer.style.removeProperty("height");
        }
    }
    var wrapper = document.querySelector('.mediaplayer-wrapper');
    var setCenter = function (val) {
        wrapper.style.paddingTop = val;
        for (var i = 0; i < mediaScreens.length; i++) {
            var mediaScreen = mediaScreens[i];
            mediaScreen.style.top = val;
        }
    };
    var formatCenterVal = function (val) { return wrapper.clientHeight > val ? (wrapper.clientHeight - val) / 2 + "px" : '0'; };
    setCenter(formatCenterVal(window.innerWidth >= window.innerHeight ? 575 : height));
}
export function random() {
    return Math.random() > 0.5 ? -1 : 1;
}
function twoDigits(d) {
    if (d <= 0) {
        return '00';
    }
    ;
    d = Math.floor(d);
    return d < 10 ? "0" + d : d;
}
export function getTime(time) {
    return twoDigits(time % 3600 / 60) + ":" + twoDigits(time % 60);
}
