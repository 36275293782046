var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import { withRouter, GLUtil, unmaskThrottle, GLGlobal, connect } from "gl-commonui";
import { PathConfig } from '@app/config/pathconfig';
import { notification } from "antd-min";
import { lazyInject, TYPES, getVpcBackgrounds, getSwyk as getSwyks, RoleHelper, Mode, getChildrenRegion, Constants as UtilConstants, ContentGroupEnum, MediaSlotEnum, selectContentForFailedObjectives, PlaylistPlayerContentTypeEnum, REPLessonPlanItemType, newGuid, ConversationSourceEnum } from "@app/util";
import ContentPlayer from "@app/components/media/playlistplayer";
import PlaylistControlContainer from "@app/components/media/playlist-controlcontainer";
import { MediaExtType, PlayMode } from "@app/components/media/mediaplayer";
import UploadLogs from "@app/service/logs/uploadlogs";
import uploadPlaylistLogs, { getUploadPlaylistLogsService, uploadProgress, getProgress, getMySetting, updateAppSettings } from "@app/service/logs/uploadplaylistlogs";
import PlaylistQuestionContainer from "@app/components/media/playlist-questioncontainer";
import { SubContentPos } from "@app/components/media/playlistmediaplayer";
import PermissionParse, { PagePermission, buildUnitPlaylist, adjustLogs } from "./extra/permission";
import { hidenHeaderFooter, showHeaderFooter } from "../components/hidenheaderfooter";
import HeartBeat from "../components/heartbeat";
import ManualSpinKeeper from "../components/manualspinkeeper";
import { Subtitles } from "@app/components/subtitle/subtitle";
import { setCompletedPlaylist, setStudentPuzzle } from "@app/states/playlistplayer";
import { PlaylistConversation } from "@app/components/media/playlist-conversation/playlist-conversation";
import moment from "moment";
import { isEmpty } from "lodash";
import { PlaylistReader } from "@app/components/media/playlist-reader/playlist-reader";
var PlaylistPlayerPage = /** @class */ (function (_super) {
    __extends(PlaylistPlayerPage, _super);
    function PlaylistPlayerPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.progressIndex = 0;
        _this.vpcCount = 0;
        _this.firstTimeUploaded = false;
        _this.playInfo = {};
        // The function on playlist end is ran
        // The function will run while playlist is still being played when conversation is last item of playlist
        _this.playlistEndFunctionRan = false;
        _this.functionToRunOnPlaylistEnd = null;
        _this.conversationIndex = null;
        _this.getStudentContentSuggestions = function (unit, versionId, objectiveRequest) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.activeLearnService.getSuggestionContentForFailedObjectives(unit, versionId, objectiveRequest)
                        .then(function (data) {
                        var suggestedContents = [];
                        // remove content without streaming media.
                        var filteredReponse = data.filter(function (f) {
                            return f.content.medias.some(function (s) { return s.mediaSlotId === MediaSlotEnum.StreamingVideo; });
                        });
                        // select minimum number of contents which targets all the failed objectives
                        suggestedContents = selectContentForFailedObjectives(filteredReponse);
                        suggestedContents = suggestedContents.filter(function (sc) { return sc.content.medias.some(function (m) { return m.mediaSlotId === MediaSlotEnum.StreamingVideo; }); });
                        return suggestedContents;
                    })];
            });
        }); };
        _this.onCurrentStarted = function (_a) {
            var subContentPos = _a.subContentPos, sequence = _a.sequence, remainingCount = _a.remainingCount, total = _a.total, content = _a.content;
            _this.setState({ manualSpinKeeperEnable: false });
            if ((!content.playerContentType || content.playerContentType === PlaylistPlayerContentTypeEnum.PlaylistContent || content.playerContentType === PlaylistPlayerContentTypeEnum.ConversationIntroVideo)
                &&
                    (!subContentPos || subContentPos === SubContentPos.middle || subContentPos === SubContentPos.end)
                &&
                    sequence !== undefined && sequence !== null
                &&
                    (!_this.firstTimeUploaded || sequence > _this.progressIndex)) {
                var indexCountToReduce = 0;
                if (_this.playlistSuggestionContent) {
                    indexCountToReduce = _this.playlistSuggestionContent.length;
                }
                if (_this.conversationIndex != null && sequence > _this.conversationIndex) {
                    indexCountToReduce += 1;
                }
                var progress = sequence === _this.state.contents.length ? 0 : sequence - indexCountToReduce;
                _this.progressIndex = sequence;
                uploadProgress(_this.state.userId, _this.state.unit, _this.state.sequence, progress);
                _this.firstTimeUploaded = true;
            }
        };
        _this.markCurrentItemAsPlayed = function (_a, uploadLogs) {
            var subContentPos = _a.subContentPos, sequence = _a.sequence;
            if (uploadLogs === void 0) { uploadLogs = false; }
            if (sequence != undefined) {
                var progress = sequence === _this.state.contents.length ? 0 : sequence - (_this.playlistSuggestionContent ? _this.playlistSuggestionContent.length : 0);
                uploadProgress(_this.state.userId, _this.state.unit, _this.state.sequence, progress);
            }
            if (uploadLogs) {
                _this.playlistEndFunctionRan = true;
                _this.onEnded();
            }
        };
        _this.onCurrentEnded = function (_a) {
            var subContentPos = _a.subContentPos, sequence = _a.sequence, content = _a.content;
            if (content.playerContentType === PlaylistPlayerContentTypeEnum.ConversationIntroVideo) {
                _this.studentService.consumeConversationIntroVideo({ id: _this.state.userId });
            }
            else {
                if (content.playerContentType === PlaylistPlayerContentTypeEnum.Suggestion) {
                    _this.remoteService.markObjectivesAsComplete(_this.state.userId, _this.state.unit, _this.playlistSuggestionContent.find(function (psc) { return psc.content.id === content.id; }).objectiveIds);
                }
                if (!subContentPos || subContentPos === SubContentPos.end) {
                    _this.progressIndex = sequence;
                    var mode = content.playerContentType === PlaylistPlayerContentTypeEnum.Suggestion ? Mode.Suggestion : Mode.Play;
                    var indexCountToReduce = 0;
                    if (_this.playlistSuggestionContent) {
                        indexCountToReduce = _this.playlistSuggestionContent.length;
                    }
                    if (_this.conversationIndex != null && sequence > _this.conversationIndex) {
                        indexCountToReduce += 1;
                    }
                    var progress = mode === Mode.Suggestion ? sequence : sequence - indexCountToReduce;
                    if (content.playerContentType === PlaylistPlayerContentTypeEnum.Suggestion) {
                        UploadLogs({ id: _this.state.userId, role: GLGlobal.store.getState().theme.leftData.role }, _this.state.unit, content, mode, _this.state.sequence, _this.progressIndex, _this.state.round);
                    }
                    else {
                        if (content.contentGroup !== ContentGroupEnum.VPC
                            || (content.contentGroup === ContentGroupEnum.VPC
                                && (!_this.prevEndedContent || _this.prevEndedContent.id !== content.id))) {
                            UploadLogs({ id: _this.state.userId, role: GLGlobal.store.getState().theme.leftData.role }, _this.state.unit, content, mode, _this.state.sequence, progress, _this.state.round);
                        }
                    }
                }
            }
            _this.prevEndedContent = content;
        };
        _this.onEnded = function () {
            var isStudentAndCodeBasedLogin = function () {
                return GLGlobal.store.getState().oidc.loginInfo.profile.from == "Code" && GLGlobal.store.getState().oidc.loginInfo.profile.role == "Student";
            };
            // This current count is catering to play-list listing screen [GL-14950].    
            if (_this.state.userId != GLGlobal.store.getState().theme.leftData.parentId || isStudentAndCodeBasedLogin()) {
                _this.studentService.getChildInfoByIdExt({ studentId: _this.state.userId }).then(function (res) {
                    if (res.unit == _this.state.unit && !res.isExtendedSubscription) {
                        var currentCount = Number(window.localStorage.getItem(_this.state.userId + '-Unit-' + _this.state.unit));
                        var updatedCount = String(currentCount + 1);
                        window.localStorage.setItem(_this.state.userId + '-Unit-' + _this.state.unit, updatedCount);
                    }
                });
            }
            // When 'functionToRunOnPlaylistEnd' is set
            // Will be set when conversation is last item in the playlist
            // Because Conversation could be marked as completed in between
            if (_this.functionToRunOnPlaylistEnd) {
                _this.functionToRunOnPlaylistEnd();
                navigateBy(_this.state, _this.props.history, "");
                return;
            }
            var requestParams = {
                studentId: _this.state.userId,
                sequence: _this.currentPlaylistPosition,
                unit: _this.state.unit,
                unitId: _this.state.unit,
                round: _this.state.round
            };
            Promise.all([
                getUploadPlaylistLogsService(_this.state.sequence, _this.state.resources.playlists, { studentId: _this.state.userId, unit: _this.state.unit, maxUnit: GLGlobal.store.getState().theme.leftData.unit }),
                uploadProgress(_this.state.userId, _this.state.unit, _this.state.sequence, 0),
                _this.puzzleService.generateStudentPuzzleSequence([requestParams])
            ]).then(function (_a) {
                var _b = __read(_a, 3), serviceContext = _b[0], nil = _b[1], studentPuzzleResponse = _b[2];
                if (studentPuzzleResponse && !isEmpty(studentPuzzleResponse)) {
                    setStudentPuzzle({
                        // set studentId and puzzleId
                        studentId: studentPuzzleResponse[0].studentId,
                        puzzleId: studentPuzzleResponse[0].puzzleId
                    });
                }
                if (serviceContext && serviceContext.log && serviceContext.log.unit) {
                    var setPlaylistCompleted = function () { return setCompletedPlaylist({
                        unit: serviceContext.log.unit,
                        round: serviceContext.round,
                        playlistId: serviceContext.playlist.id,
                    }); };
                    if (!_this.playlistEndFunctionRan) {
                        setPlaylistCompleted();
                    }
                    else {
                        _this.functionToRunOnPlaylistEnd = setPlaylistCompleted;
                    }
                }
                uploadPlaylistLogs(serviceContext ? __assign({}, serviceContext, { logs: adjustLogs(_this.playInfo.playlists, serviceContext.logs) }) : serviceContext, _this.state.contents, _this.vpcCount, RoleHelper.isStudent(GLGlobal.store.getState().theme.leftData.role)).then(function () {
                    !_this.playlistEndFunctionRan && navigateBy(_this.state, _this.props.history, "");
                });
            });
        };
        _this.onStop = function () {
            navigateBy(_this.state, _this.props.history, "");
        };
        _this.onDropdownVisibleChange = function (visible) {
            _this.setState({ visibleBar: visible });
        };
        _this.onPlaybackRateChange = function (value) {
            _this.setState({ playbackRate: value });
            updateAppSettings(_this.state.userId, GLGlobal.store.getState().theme.leftData.parentId, GLGlobal.store.getState().theme.leftData.unit, { slowSpeed: value != 1 });
        };
        _this.onCaptionShowChange = function (visible) {
            _this.setState({ captionShow: visible });
            _this.updateHideSubtitle(visible);
        };
        _this.setManualSpinKeeper = function (enabled) {
            _this.setState({ manualSpinKeeperEnable: enabled });
        };
        var _a = _this.props.match.params, userId = _a.userId, versionId = _a.versionId, unit = _a.unit, sequence = _a.sequence;
        _this.state = {
            userId: userId,
            regionId: null,
            versionId: versionId,
            unit: unit,
            backgroundAudios: [],
            swyks: [],
            resources: { unit: 0, playlists: null, contents: null, questions: null },
            signature: {},
            sequence: sequence,
            currentPlaylist: null,
            contents: [],
            subtitles: [],
            contentPageTimings: [],
            progressStartIndex: 0,
            manualSpinKeeperEnable: true,
            playbackRate: 1,
            visibleBar: false,
            captionShow: false,
            round: 1,
            mySetting: {}
        };
        return _this;
    }
    PlaylistPlayerPage.prototype.isSubTitleShow = function (unit) {
        return parseInt(unit) > 8;
    };
    PlaylistPlayerPage.prototype.getSubTitle = function (unit, hideSubtitle) {
        if (hideSubtitle !== undefined && hideSubtitle !== null) {
            return !hideSubtitle;
        }
        return this.isSubTitleShow(unit);
    };
    PlaylistPlayerPage.prototype.needResetSubTitle = function (unitsPlaylists, playListLog, progress) {
        var _a = this.props.match.params, sequence = _a.sequence, unit = _a.unit;
        var isFirstPlaylist = unitsPlaylists.find(function (playlist) { return playlist.id === sequence; }).sequence === 1;
        var isRoundOne = (playListLog && playListLog.round ? playListLog.round : 1) === 1;
        var hasNoProgress = (progress && progress.contentIndex ? progress.contentIndex : 0) === 0;
        var currentMaxUnit = GLGlobal.store.getState().theme.leftData.unit;
        var isCurrentUserMaxUnit = currentMaxUnit && currentMaxUnit === parseInt(unit);
        return isFirstPlaylist && isRoundOne && hasNoProgress && isCurrentUserMaxUnit;
    };
    PlaylistPlayerPage.prototype.resetSubTitle = function (needResetSubTitle, unit, settings) {
        if (needResetSubTitle) {
            var captionShow = this.isSubTitleShow(unit);
            settings.mySetting.appSettings.hideSubtitle = !captionShow;
            this.updateHideSubtitle(captionShow);
            this.setState({
                captionShow: captionShow
            });
        }
        else {
            this.setState({
                captionShow: this.getSubTitle(unit, settings.mySetting.appSettings.hideSubtitle),
            });
        }
    };
    PlaylistPlayerPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, userId, versionId, unit, sequence, _b, id, rest, maxUnit, allChildren, currentChild;
            var _this = this;
            return __generator(this, function (_c) {
                _a = this.state, userId = _a.userId, versionId = _a.versionId, unit = _a.unit, sequence = _a.sequence;
                if (!PagePermission(userId, unit)) {
                    return [2 /*return*/];
                }
                _b = GLGlobal.store.getState().theme.leftData, id = _b.id, rest = __rest(_b, ["id"]);
                maxUnit = rest.unit || rest.lsUnit;
                allChildren = getChildrenRegion();
                currentChild = allChildren.filter(function (c) { return c.id == userId; })[0];
                Promise.all([
                    this.contentsignatureappservice.getSignatures(null, { versionId: versionId }),
                    this.playlistConnectService.getContent({ versionId: versionId, id: sequence, isCustomLessonPlan: currentChild && currentChild.repMethodologyVersionId ? true : false }),
                    this.unitsPlaylistsService.getplaylists({ units: [unit], lessonplanVersionId: currentChild && currentChild.repMethodologyVersionId }, { versionId: versionId }),
                    getProgress(userId, unit, sequence),
                    this.playlistlogAppService.getPlaylistlog({ unit: unit, studentId: userId }),
                    getMySetting(this.settingService, id, maxUnit),
                    this.remoteService.getStudentFailedObjectives(currentChild.id, unit),
                    this.studentService.hasConsumedConversationIntroVideo({ id: userId }),
                    this.conversationIntroService.getConversationIntroVideo(),
                    this.conversationAdminService.checkAccess(userId)
                ]).then(function (_a) {
                    var _b = __read(_a, 10), signatures = _b[0], playlistcontents = _b[1], unitsPlaylists = _b[2], progress = _b[3], playListLog = _b[4], setting = _b[5], studentFailedObjectives = _b[6], hasWatchedConversationVideo = _b[7], conversationIntroVideoUrl = _b[8], hasTalkTimeAccess = _b[9];
                    return __awaiter(_this, void 0, void 0, function () {
                        var _c, unitPlaylist, suggestionContent, playlistIdsToExcludePlaylistContent, currentPlayListIndex, nextPlaylistId, objectiveRequest, suggestions, questions, progressIndex, search, pg, progressStartIndex, mergedPlaylistContents, insertIndex, currentPlaylist, items, indexToInsertAt, currentPlayListIndex, introVideoContentId, introVideoId, playlistConversationIntroInfo, conversationIntroContent, conversationIndex, canPlay, subtitles;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    unitPlaylist = buildUnitPlaylist(unitsPlaylists, playListLog, unit).unitPlaylist;
                                    this.playInfo.playlists = unitPlaylist.playlists;
                                    suggestionContent = [];
                                    if (!studentFailedObjectives.length) return [3 /*break*/, 2];
                                    playlistIdsToExcludePlaylistContent = [sequence];
                                    currentPlayListIndex = this.playInfo.playlists.findIndex(function (p) { return p.pid === sequence; });
                                    if (currentPlayListIndex !== this.playInfo.playlists.length - 1) {
                                        nextPlaylistId = this.playInfo.playlists[currentPlayListIndex + 1].pid;
                                        playlistIdsToExcludePlaylistContent.push(nextPlaylistId);
                                    }
                                    objectiveRequest = {
                                        objectiveIds: studentFailedObjectives,
                                        playlistIds: playlistIdsToExcludePlaylistContent,
                                        lessonPlanVersionId: currentChild.repMethodologyVersionId
                                    };
                                    return [4 /*yield*/, this.getStudentContentSuggestions(unit, versionId, objectiveRequest)];
                                case 1:
                                    suggestions = _d.sent();
                                    suggestionContent.push.apply(suggestionContent, __spread(suggestions));
                                    this.playlistSuggestionContent = suggestionContent.length ? suggestionContent : [];
                                    _d.label = 2;
                                case 2:
                                    // actual position of the playlist is required
                                    this.currentPlaylistPosition = unitPlaylist.playlists.find(function (p) { return p.pid === sequence; }).index + 1;
                                    return [4 /*yield*/, this.contentService.getVersionQuestions({ playlistId: sequence, lessonplanVersionId: currentChild && currentChild.repMethodologyVersionId, round: playListLog && playListLog.round ? playListLog.round : 1 })];
                                case 3:
                                    questions = _d.sent();
                                    progressIndex = Number.NaN;
                                    if (!GLGlobal.workEnv().isProdction()) {
                                        search = window.location.search;
                                        if (search && search.startsWith('?')) {
                                            pg = search.slice(1).split('&').find(function (s) { return s.startsWith('pg'); });
                                            if (pg) {
                                                progressIndex = Number(pg.split('=')[1]) - 1;
                                            }
                                        }
                                    }
                                    progressStartIndex = Number.isNaN(progressIndex) ? (progress && progress.contentIndex ? progress.contentIndex : 0) : progressIndex;
                                    mergedPlaylistContents = playlistcontents.contents.map(function (c) {
                                        return __assign({}, c, { playerContentType: PlaylistPlayerContentTypeEnum.PlaylistContent });
                                    });
                                    insertIndex = suggestionContent.length && progressStartIndex > 0 ? progressStartIndex + 1 : progressStartIndex;
                                    mergedPlaylistContents.splice.apply(mergedPlaylistContents, __spread([insertIndex, 0], suggestionContent.map(function (sc) {
                                        return __assign({}, sc.content, { playerContentType: PlaylistPlayerContentTypeEnum.Suggestion });
                                    })));
                                    currentPlaylist = unitsPlaylists.find(function (pl) { return pl.id == sequence; });
                                    if (suggestionContent.length) {
                                        items = mergedPlaylistContents
                                            .filter(function (mpc) { return mpc.playerContentType === PlaylistPlayerContentTypeEnum.Suggestion; })
                                            .map(function (mpc) {
                                            return {
                                                id: mpc.id,
                                                intId: null,
                                                mediaSlotId: MediaSlotEnum.ExposureVideo,
                                                type: REPLessonPlanItemType.Normal
                                            };
                                        });
                                        indexToInsertAt = mergedPlaylistContents.findIndex(function (mpc) { return mpc.playerContentType === PlaylistPlayerContentTypeEnum.Suggestion; });
                                        (_c = currentPlaylist.contents).splice.apply(_c, __spread([indexToInsertAt, 0], items));
                                        currentPlayListIndex = unitsPlaylists.findIndex(function (pl) { return pl.id == sequence; });
                                        unitsPlaylists.splice.apply(unitsPlaylists, __spread([currentPlayListIndex, 1], currentPlaylist));
                                    }
                                    if (hasTalkTimeAccess && !hasWatchedConversationVideo && currentPlaylist && currentPlaylist.contents && currentPlaylist.contents.some(function (c) { return c.type === REPLessonPlanItemType.Conversation; })) {
                                        introVideoContentId = newGuid();
                                        introVideoId = newGuid();
                                        playlistConversationIntroInfo = { id: introVideoContentId, type: REPLessonPlanItemType.Normal };
                                        conversationIntroContent = {
                                            id: introVideoContentId, contentGroup: ContentGroupEnum.ConversationIntroVideo, playerContentType: PlaylistPlayerContentTypeEnum.ConversationIntroVideo, medias: [{
                                                    id: introVideoId,
                                                    mediaSlotId: MediaSlotEnum.StreamingVideo,
                                                    mediaType: MediaExtType.MP4,
                                                    url: conversationIntroVideoUrl,
                                                    src: conversationIntroVideoUrl
                                                }],
                                            pages: [],
                                            updateTime: moment(new Date()).utc().format()
                                        };
                                        conversationIndex = currentPlaylist.contents.findIndex(function (c) { return c.type === REPLessonPlanItemType.Conversation; });
                                        if (conversationIndex > -1) {
                                            mergedPlaylistContents.splice(0, 0, conversationIntroContent);
                                            currentPlaylist.contents.splice(conversationIndex, 0, playlistConversationIntroInfo);
                                            this.conversationIndex = conversationIndex;
                                        }
                                    }
                                    this.resetSubTitle(this.needResetSubTitle(unitsPlaylists, playListLog, progress), unit, setting);
                                    if (!(userId && GLGlobal.store.getState().theme.leftData.role)) return [3 /*break*/, 5];
                                    return [4 /*yield*/, PermissionParse(userId, GLGlobal.store.getState().theme.leftData.role, unitPlaylist, setting)];
                                case 4:
                                    _d.sent();
                                    canPlay = unitPlaylist.playlists.some(function (p) { return p.pid === sequence && p.isEnable; });
                                    if (!canPlay) {
                                        navigateBy(this.state, this.props.history, "");
                                    }
                                    _d.label = 5;
                                case 5:
                                    this.setState({
                                        regionId: currentChild.regionId,
                                        captionShow: this.getSubTitle(unit, setting.mySetting.appSettings.hideSubtitle),
                                        backgroundAudios: getVpcBackgrounds(),
                                        swyks: getSwyks(),
                                        resources: { unit: unit, playlists: unitsPlaylists, contents: mergedPlaylistContents, questions: questions },
                                        signature: signatures,
                                        sequence: sequence,
                                        currentPlaylist: currentPlaylist,
                                        contents: mergedPlaylistContents.concat(),
                                        contentPageTimings: mergedPlaylistContents.map(function (e) { return ({
                                            contentId: e.id,
                                            pageTimings: e.pages.map(function (e) { return e.pageTiming; })
                                        }); }),
                                        progressStartIndex: progressStartIndex,
                                        round: playListLog ? playListLog.round : 1,
                                        mySetting: setting.mySetting
                                    });
                                    this.vpcCount = playlistcontents.vpcCount;
                                    this.progressIndex = this.state.progressStartIndex;
                                    return [4 /*yield*/, this.resourceService.getContentSubtitles({ contentIds: mergedPlaylistContents.map(function (content) { return content.id; }) })];
                                case 6:
                                    subtitles = _d.sent();
                                    this.setState({ subtitles: subtitles });
                                    return [2 /*return*/];
                            }
                        });
                    });
                });
                hidenHeaderFooter();
                return [2 /*return*/];
            });
        });
    };
    PlaylistPlayerPage.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.leftData && (this.props.leftData.unit !== nextProps.leftData.unit)) {
            this.componentDidMount();
        }
    };
    PlaylistPlayerPage.prototype.questionUploadLogs = function (d) {
        this.answerService.createAnswerLog([__assign({ lastUsedDate: new Date(), studentId: this.state.userId }, d)]);
    };
    PlaylistPlayerPage.prototype.componentWillUnmount = function () {
        showHeaderFooter();
    };
    PlaylistPlayerPage.prototype.updateHideSubtitle = function (visible) {
        updateAppSettings(this.state.userId, GLGlobal.store.getState().theme.leftData.parentId, GLGlobal.store.getState().theme.leftData.unit, { hideSubtitle: !visible });
    };
    PlaylistPlayerPage.prototype.render = function () {
        var _this = this;
        var conversationSpeed = UtilConstants.DefaultPlaybackRate; // Done as per GL-13101
        return React.createElement("div", { className: "playlistplayer media-page" },
            React.createElement(HeartBeat, { userId: this.state.userId },
                React.createElement(ManualSpinKeeper, { enable: this.state.manualSpinKeeperEnable }),
                React.createElement(ContentPlayer, { backgroundAudios: this.state.backgroundAudios, swyks: this.state.swyks, onLoaded: function (loaded) { return loaded && unmaskThrottle(); }, onCurrentStarted: this.onCurrentStarted, markCurrentItemAsPlayed: this.markCurrentItemAsPlayed, onStop: this.onStop, onCurrentEnded: this.onCurrentEnded, onEnded: this.onEnded, resources: this.state.resources, signature: this.state.signature, playlistId: this.state.sequence, startIndex: this.state.progressStartIndex, playMode: PlayMode.order, playbackRate: this.state.playbackRate, visibleBar: this.state.visibleBar, mySetting: this.state.mySetting, onPlaybackRateChange: this.onPlaybackRateChange },
                    React.createElement(PlaylistQuestionContainer, { onSelectCandidator: function (d) { return _this.questionUploadLogs(d); } }),
                    React.createElement(PlaylistConversation, { regionId: this.state.regionId, unit: this.state.unit, studentId: this.state.userId, conversationSpeed: this.state.playbackRate, versionId: this.state.versionId, source: ConversationSourceEnum.playlist, playlistNumber: this.props.location.state.playlistNumber, onDropdownVisibleChange: this.onDropdownVisibleChange, onPlaybackRateChange: this.onPlaybackRateChange }),
                    React.createElement(PlaylistReader, { onReaderEnded: this.onCurrentEnded, setManualSpinKeeper: this.setManualSpinKeeper }),
                    React.createElement(Subtitles, { visible: this.state.captionShow, subtitles: this.state.subtitles, contentPageTimings: this.state.contentPageTimings }),
                    React.createElement(PlaylistControlContainer, { controlOnScreen: true, playbackRate: this.state.playbackRate, onDropdownVisibleChange: this.onDropdownVisibleChange, captionShow: this.state.captionShow, onCaptionShowChange: this.onCaptionShowChange, onPlaybackRateChange: this.onPlaybackRateChange }))));
    };
    __decorate([
        lazyInject(TYPES.IContentsignatureAppService)
    ], PlaylistPlayerPage.prototype, "contentsignatureappservice", void 0);
    __decorate([
        lazyInject(TYPES.IUnitsPlaylistsService)
    ], PlaylistPlayerPage.prototype, "unitsPlaylistsService", void 0);
    __decorate([
        lazyInject(TYPES.IPlaylistConnectService)
    ], PlaylistPlayerPage.prototype, "playlistConnectService", void 0);
    __decorate([
        lazyInject(TYPES.IContentService)
    ], PlaylistPlayerPage.prototype, "contentService", void 0);
    __decorate([
        lazyInject(TYPES.IAnswerService)
    ], PlaylistPlayerPage.prototype, "answerService", void 0);
    __decorate([
        lazyInject(TYPES.IPlaylistlogsAppService)
    ], PlaylistPlayerPage.prototype, "playlistlogAppService", void 0);
    __decorate([
        lazyInject(TYPES.ISettingService)
    ], PlaylistPlayerPage.prototype, "settingService", void 0);
    __decorate([
        lazyInject(TYPES.IResourceService)
    ], PlaylistPlayerPage.prototype, "resourceService", void 0);
    __decorate([
        lazyInject(TYPES.IPuzzleService)
    ], PlaylistPlayerPage.prototype, "puzzleService", void 0);
    __decorate([
        lazyInject(TYPES.IRemoteService)
    ], PlaylistPlayerPage.prototype, "remoteService", void 0);
    __decorate([
        lazyInject(TYPES.IActiveLearnService)
    ], PlaylistPlayerPage.prototype, "activeLearnService", void 0);
    __decorate([
        lazyInject(TYPES.IConversationIntroService)
    ], PlaylistPlayerPage.prototype, "conversationIntroService", void 0);
    __decorate([
        lazyInject(TYPES.IStudentService)
    ], PlaylistPlayerPage.prototype, "studentService", void 0);
    __decorate([
        lazyInject(TYPES.IConversationAdminService)
    ], PlaylistPlayerPage.prototype, "conversationAdminService", void 0);
    PlaylistPlayerPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        }, { setStudentPuzzle: setStudentPuzzle })
    ], PlaylistPlayerPage);
    return PlaylistPlayerPage;
}(Component));
export default PlaylistPlayerPage;
function navigateBy(state, history, message) {
    if (state.unit || state.lsUnit) {
        var userId = state.userId, versionId = state.versionId, unit = state.unit;
        history.push({ pathname: GLUtil.pathStringify(PathConfig.Playlist, { userId: userId, unit: unit, versionId: versionId }) });
    }
    else {
        notification.warn({ message: message });
    }
}
