var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getChildrenRegion, getPlatformInfo, newGuid, RoleHelper } from "@app/util";
import { GlCommonAppVersion } from "@app/util/conversation-api/utils/constants";
import { ServerContentGroupEnum } from "@app/util/enum";
import { ReportService } from "../app/report";
var moment = require("moment");
export default function UploadLogs(user, unit, content, mode, playlistId, contentIndex, round) {
    return __awaiter(this, void 0, void 0, function () {
        var reportService, serverContentGroup, duration, medias, date, log, allChildren, child, extendedInfo, pInfo, contentPlayLogs, response, failedToPost, error_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(RoleHelper.isStudent(user.role) &&
                        content &&
                        content.id &&
                        content.contentGroup)) return [3 /*break*/, 9];
                    reportService = new ReportService();
                    serverContentGroup = ServerContentGroupEnum[content.contentGroup];
                    duration = 0;
                    if (content.skillTypeId) {
                        medias = content.readerContents.map(function (content) { return content.medias; });
                        duration = medias
                            .map(function (media) { return media.length; })
                            .reduce(function (sum, len) { return sum + len; });
                    }
                    else {
                        duration = content.medias
                            .map(function (media) { return media.length; })
                            .reduce(function (sum, len) { return sum + len; });
                    }
                    date = new Date();
                    date.setMilliseconds(date.getMilliseconds() - (duration ? duration : 0));
                    log = {
                        id: newGuid(),
                        studentId: user.id,
                        unit: unit,
                        contentId: content.id,
                        contentTitle: content.name,
                        contentGroupId: serverContentGroup,
                        startClientTime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                        start: moment(new Date()).utc(),
                        duration: duration,
                        client: 1,
                        playlistId: playlistId,
                        contentIndex: contentIndex,
                        round: round,
                        AppVersion: GlCommonAppVersion
                    };
                    allChildren = getChildrenRegion();
                    child = allChildren.find(function (u) { return u.id === user.id; });
                    extendedInfo = {};
                    if (child) {
                        extendedInfo.regionId = child.regionId;
                        pInfo = getPlatformInfo();
                        extendedInfo.browserId = pInfo.browser;
                        extendedInfo.operatingSystemId = pInfo.os;
                        extendedInfo.browserVersion = pInfo.bVersion;
                        extendedInfo.oSVersion = pInfo.oVersion;
                        extendedInfo.schoolId = child.schoolId ? child.schoolId : null;
                        extendedInfo.schoolClassId = child.schoolClassId
                            ? child.schoolClassId
                            : null;
                        extendedInfo.campusId = child.campusId ? child.campusId : null;
                    }
                    contentPlayLogs = [
                        __assign({ mode: mode }, log, extendedInfo)
                    ];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 9]);
                    return [4 /*yield*/, reportService.sendElklogsV2(contentPlayLogs)];
                case 2:
                    response = _a.sent();
                    if (!(response &&
                        response.message &&
                        Array.isArray(response.message))) return [3 /*break*/, 6];
                    failedToPost = response.message;
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, reportService.sendElklogsV2(failedToPost)];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 9];
                case 7:
                    error_2 = _a.sent();
                    return [4 /*yield*/, reportService.sendElklogsV2(contentPlayLogs)];
                case 8:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    });
}
